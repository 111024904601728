
//colors
$green: #00C577 !default;
$gray: #18232D!default;
$blue: #002879 !default;
$white: #ffffff !default;
$black: #000000 !default;

//theme color variable
$primary: $green !default;
$secondary: $gray !default;
$success: $blue !default;

$gray-100: #747b81 !default;
$gray-200: #5d656c !default;
$gray-300: #464f57 !default;
$gray-400: #2f3942 !default;

$blue-100: #CCD4E4 !default;
$blue-200: #99A9C9 !default;

// border-radius-variables
$border-radius:                     0.625rem !default;
$border-radius-lg:                  1.25rem !default;

// buttons
$btn-padding-y:                  0.75rem !default;
$btn-padding-x:                  1.563rem !default;
$btn-focus-box-shadow:              0 !default;

