.new-logo-image {
    height: 60px;
    object-fit: contain !important;
}

.image-object-fit-cover {
    object-fit: cover !important;
}

.feature-image-card {
    height: 70px;
    border-radius: 8px;
}

header {
    .navbar-nav {
        .dropdown {
            z-index: 1;
        }
    }

    .navbar-toggler {
        @media (max-width: 991px) {
            position: absolute;
            top: 16px;
            right: 12px;
        }
    }
    .header-btn {
        @media (max-width: 991px) {
            padding-right: 50px;
        }
    }
}

.dropdown-menu {
    .active {
        color: #fff !important;
        text-decoration: none !important;
        background-color: #00c577 !important;
    }
}

.header {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all .5s ease-in-out;
    z-index: 1
}

@media(max-width: 991px) {
    .header {
        padding-top: 15px;
        padding-bottom: 15px
    }
}

.header.fixed {
    background-color: #fff;
    box-shadow: 0px 3px 10px 0px rgb(38 107 193 / 8%);
}

#toast-container .toast.toast-error {
    opacity: 1;
}
#toast-container .toast.toast-success {
    opacity: 1;
}
.p-t-100{
    padding-top: 100px !important;
}
footer .copy-right {
    border-top: 1px solid #dcdcdc;
    color: #a9a9a9;
}

.features__features-icon {
    width: 70px;
    height: 70px;
    border-radius: .625rem;
    margin-bottom: 30px
}

.js-cookie-consent {
    background-color: #5775b5 !important;
}
